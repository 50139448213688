import React, { useEffect, useState } from 'react'

const ScrollTop = () => {

    const [scrollPosition , setScrollPosition] = useState(0);

    const handelScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    }

    useEffect(() => {
      window.addEventListener('scroll', handelScroll , { passive: true });

      return () => {
        window.removeEventListener('scroll', handelScroll);
      }
    }, []);

    const handelScrollTop = () => {
        window.scrollTo(0,0);
    }

  return (
    <div id="stop" class="scrollTop" style={{opacity: scrollPosition > 100 ? 1 : 0 }}>
	<button onClick={handelScrollTop}><span><i class="fas fa-angle-up"></i></span></button>
</div>
  )
}

export default ScrollTop
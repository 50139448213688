export const generateCaptcha = () => {
    // Generate a random captcha string
    const captchaChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += captchaChars.charAt(
        Math.floor(Math.random() * captchaChars.length)
      );
    }
     return captcha
  };
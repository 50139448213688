import React, { useEffect, useState } from "react";

// Owl Carousel For React
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import data for slider
import { Link } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import axios from "axios";

import parse from "html-react-parser";
import { generateCaptcha } from "../../functions/generateCaptcha";

// import slider Data

const MainSlider = () => {
  const [mainSliderData, setMainSliderData] = useState([]);
  const [sliderFormInfo, setSliderFormInfo] = useState();
  console.log(sliderFormInfo);
  const [captchaText, setCaptchaText] = useState("");
  console.log(mainSliderData);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getMainSlidersForView")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setMainSliderData(res.data);
          }
        });
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/frontendForms/getSliderFormInfo")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setSliderFormInfo(res.data.data);
          }
        });
    };

    fetchData();
    setCaptchaText(generateCaptcha);
  }, []);

  //Injects Properties to Owl Carousel

  const MainSliderProps = {
    className: "owl-carousel owl-theme",
    id: "main-slider",
    loop: true,
    navText: [
      "<i class='fa  fa-angle-left '></i>",
      "<i class='fa  fa-angle-right '></i>",
    ],
    nav: false,
    autoplay: true,
    dots: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const initialFormValues = {
    name: "",
    email: "",
    phone: "",
    description: "",
    captcha: "",
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  return (
    <>
      {mainSliderData.length > 0 && (
        <section class="slider-wrap">
          <OwlCarousel {...MainSliderProps}>
            {mainSliderData.map((element, index) => {
              return (
                <>
                  <div key={index} class="slide-sec">
                    <div class="slide-img">
                      <img
                        src={`https://backend.seo-calgary.ca/${element.sliderImage}`}
                        alt={`Slider_Image_${index}`}
                      />
                    </div>
                    <div class="inner-cap">
                      {element.heading && <h1>{parse(element.heading)}</h1>}
                      <p>{element.description}</p>
                      {element.features && (
                        <ul>
                          {element.features.map((e, i) => {
                            return (
                              <>
                                <li key={i}>{e}</li>
                              </>
                            );
                          })}
                        </ul>
                      )}
                      <div class="cap-btn">
                        {element.buttonText && (
                          <Link class="chat-n" to="/">
                            {element.buttonText}
                          </Link>
                        )}
                        {element.action && (
                          <a class="disc" href="tel:+(858) 707-7622">
                            {parse(element.action)}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </OwlCarousel>
          <div class="slider-form">
            <Formik
              initialValues={initialFormValues}
              validationSchema={Yup.object({
                name: Yup.string()
                  .max(20, "Must Be 20 Letters Only")
                  .required("Name is Required"),
                email: Yup.string()
                  .email("Field should contain a valid e-mail")
                  .max(255)
                  .required("E-mail is required"),
                phone: Yup.string()
                  .matches(phoneRegExp, "Phone number is not valid")
                  .required("phone Number is required"),
                description: Yup.string()
                  .max(200, "Must Be 200 Letters Only")
                  .required("Description is Required"),
                captcha: Yup.string()
                  .required("Captcha is Required")
                  .test("captcha-matches", "captcha must match", (value) => {
                    return captchaText === value;
                  }),
              })}
              onSubmit={(values) => {
                // if formData Needed
                // const formData = new FormData();
                // formData.append('name' , values.name);
                console.log(values);

                axios
                  .post(
                    "https://backend.seo-calgary.ca/api/v1/frontendForms/addsliderFormData",
                    values
                  )
                  .then((res) => {
                    if (res.data.error) {
                      window.alert(res.data.error);
                    } else {
                      window.alert(res.data.message);
                    }
                  });
              }}
            >
              <Form className="row">
                <h2>
                  {sliderFormInfo && sliderFormInfo.slider_contact_info_header}
                </h2>
                <div class="col-md-12">
                  <span className="error-message">
                    <ErrorMessage name="name" />
                  </span>
                  <Field
                    type="text"
                    class="form-control"
                    placeholder="Name *"
                    aria-label="First name"
                    name="name"
                    id="name"
                  />
                </div>
                <div class="col-md-12">
                  <span className="error-message">
                    <ErrorMessage name="email" />
                  </span>
                  <Field
                    type="email"
                    class="form-control"
                    placeholder="Email *"
                    aria-label="Email"
                    name="email"
                    id="email"
                  />
                </div>
                <div class="col-md-12">
                  <span className="error-message">
                    <ErrorMessage name="phone" />
                  </span>
                  <Field
                    type="text"
                    class="form-control"
                    placeholder="Phone Number"
                    aria-label="Phone Number"
                    name="phone"
                    id="phone"
                  />
                </div>
                <div class="col-md-12">
                  <span className="error-message">
                    <ErrorMessage name="description" />
                  </span>
                  <Field
                    as="textarea"
                    class="form-control"
                    placeholder="To help us understand better, write a brief description about your project"
                    aria-label="Description"
                    name="description"
                    rows="4"
                    id="description"
                  />
                </div>
                <div class="col-md-12">
                  <p>Captcha Text : {captchaText && captchaText}</p>
                </div>
                <div class="col-md-12">
                  <span className="error-message">
                    <ErrorMessage name="captcha" />
                  </span>
                  <Field
                    type="text"
                    class="form-control"
                    placeholder="Captcha Text Here *"
                    aria-label="captcha"
                    name="captcha"
                    id="captcha"
                  />
                </div>
                <div class="col-md-12">
                  <Field
                    type="submit"
                    class="btn btn-primary"
                    value=" Get a free quote"
                    name="submit"
                    id="submit"
                  />
                </div>
                <small>
                  {sliderFormInfo && sliderFormInfo.slider_contact_info_footer}
                </small>
              </Form>
            </Formik>
          </div>
        </section>
      )}
    </>
  );
};

export default MainSlider;

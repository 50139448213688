import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

const Footer = () => {
  const [footerData, setFooterData] = useState();

  useEffect(() => {
    const fetchFooterData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getFooter")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setFooterData(res.data.data);
            console.log(res.data.message);
          }
        });
    };

    fetchFooterData();
  }, []);
  return (
    <>
      {footerData && (
        <footer>
          <div class="container">
            {/* <div class="row">
			<div class="col-lg-3">
				<div class="foot-wrap">
					<h6>Web Design</h6>
					<ul class="footer-link">
						<li><a href="service-single.php">Web Design</a></li>
						<li><a href="service-single.php">eCommerce Website</a></li>
						<li><a href="service-single.php">Web product Development</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-3">
				<div class="foot-wrap">
					<h6>eCommerce</h6>
					<ul class="footer-link">
						<li><a href="service-single.php">Web Design</a></li>
						<li><a href="service-single.php">eCommerce Website</a></li>
						<li><a href="service-single.php">Web product Development</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-3">
				<div class="foot-wrap">
					<h6>Branding</h6>
					<ul class="footer-link">
						<li><a href="service-single.php">Web Design</a></li>
						<li><a href="service-single.php">eCommerce Website</a></li>
						<li><a href="service-single.php">Web product Development</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-3">
				<div class="foot-wrap">
					<h6>Web Apps</h6>
					<ul class="footer-link">
						<li><a href="service-single.php">Web Design</a></li>
						<li><a href="service-single.php">eCommerce Website</a></li>
						<li><a href="service-single.php">Web product Development</a></li>
					</ul>
				</div>
			</div>
		</div> */}
            <div class="mid-footer">
              <div class="row">
                <div class="col-lg-3">
                  <div class="footer-contct">
                    <strong>follow us on</strong>
                    <ul class="socio">
                      <li>
                        <a href={footerData.facebook_url}>
                          <i class="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href={footerData.twitter_url}>
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href={footerData.linkendIn_url}>
                          <i class="fab fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href={footerData.youtube_url}>
                          <i class="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href={footerData.instagram_url}>
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="footer-contct">
                    <strong>Address</strong>
                    <ul class="foot-contct">
                      <li>{footerData.addressLine1}</li>
                      <li>{footerData.addressLine2}</li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="footer-contct">
                    <strong>Email</strong>
                    <ul class="foot-contct">
                      <li>
                        <a href={`mailto:${footerData.email1}`}>
                          {footerData.email1}
                        </a>
                      </li>
                      <li>
                        <a href={`mailto:${footerData.email2}`}>
                          {footerData.email2}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="footer-contct">
                    <strong>Phone</strong>
                    <ul class="foot-contct">
                      <li>
                        <a href={`tel:${footerData.phone1}`}>
                          {footerData.phone1}
                        </a>
                      </li>
                      <li>
                        <a href={`tel:${footerData.phone2}`}>
                          {footerData.phone2}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="copy-r">
              <strong>{footerData.copyright_text}</strong>
              <ul>
                <li>
                  <a href="/">Disclaimer</a>
                </li>
                <li>
                  <a href="/">Terms & Condition</a>
                </li>
                <li>
                  <a href="/">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;

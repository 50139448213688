// Owl Carousel For React
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";

import axios from "axios";

const Testimonials = () => {
  const [testimonialData, setTestimonialData] = useState(null);
  useEffect(() => {
    axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getTestimonialsForView")
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
        } else {
          setTestimonialData(res.data.data);
        }
      });
  }, []);
  console.log(testimonialData);
  const TestimonialProps = {
    className: "owl-carousel owl-theme",
    id: "testi-slider",
    loop: true,
    navText: [
      "<i class='fas  fa-angle-left '></i>",
      "<i class='fas fa-angle-right '></i>",
    ],
    nav: true,
    autoplay: true,
    dots: false,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <>
      {testimonialData && (
        <section class="testimonials">
          <div class="container">
            <div class="headings-extra">
              <h5>{testimonialData.heading}</h5>
              <small>{testimonialData.description}</small>
            </div>
            <OwlCarousel {...TestimonialProps}>
              {testimonialData.cardData &&
                testimonialData.cardData.map((element, key) => {
                  return (
                    <>
                      <div class="test-wrap">
                        <div class="testi-dtl">
                          <div class="test-img">
                            <img
                              src={`https://backend.seo-calgary.ca/${element.image}`}
                              alt={element.location}
                            />
                          </div>
                          <p>{element.description}</p>
                          <div class="testi-name">
                            <strong>{element.reviewer}</strong>
                            <small>{element.location}</small>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </OwlCarousel>
          </div>
        </section>
      )}
    </>
  );
};

export default Testimonials;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [siteSettingData, setSiteSettingData] = useState();

  const [navbarData, setNavbarData] = useState();

  const [headerTopData, setHeaderTopData] = useState();

  const handelScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handelScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handelScroll);
    };
  }, []);

  useEffect(() => {
    const fetchSiteSettingData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/settings/getSettings")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setSiteSettingData(res.data.data);
            console.log(res.data.message);
          }
        });
    };

    fetchSiteSettingData();
  }, []);
  useEffect(() => {
    const headerTopData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getHeaderLinks")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setHeaderTopData(res.data.data);
            console.log(res.data.message);
          }
        });
    };

    headerTopData();
  }, []);
  useEffect(() => {
    const fetchNavbarData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getNavLinksForWebsite")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setNavbarData(res.data.data);
            console.log(res.data.message);
          }
        });
    };

    fetchNavbarData();
  }, []);

  return (
    <>
      <header
        id="navhead"
        className={scrollPosition > 60 ? "fixed-to-top" : null}
      >
        <div className="head-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-12">
                {siteSettingData && siteSettingData.logo ? (
                  <div className="logo-wrap">
                    <Link to="/">
                      <img
                        src={`https://backend.seo-calgary.ca/${siteSettingData.logo}`}
                        alt={siteSettingData && siteSettingData.comapny_name}
                      />
                    </Link>
                  </div>
                ) : (
                  <>
                    <strong>
                      {siteSettingData && siteSettingData.comapny_name}
                    </strong>
                  </>
                )}
              </div>
              <div className="col-lg-9 col-md-12">
                <div className="header-rt">
                  <div className="mid-bar">
                    <ul className="call-us">
                      {headerTopData &&
                        headerTopData.map((element, index) => (
                          <>
                            <li key={index}>
                              {element.name === "Request a quote" ? (
                                <a href="#" data-bs-toggle="modal" data-bs-target="#reqpModal">
                                  <i className={element.icon}></i>
                                  <strong>{element.name} </strong>
                                </a>
                              ) : (
                                <a href={element.route}>
                                  <i className={element.icon}></i>
                                  <strong>{element.name} </strong>
                                </a>
                              )}
                            </li>
                          </>
                        ))}
                    </ul>
                    <div className="nav-sec">
                      <nav className="navbar navbar-expand-lg navbar-light">
                        <button
                          className="navbar-toggler"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          <span className="fas fa-bars"></span>
                        </button>

                        <div
                          className="collapse navbar-collapse justify-content-flexend"
                          id="navbarSupportedContent"
                        >
                          <ul className="main-navigation">
                            <li className="menu-item active">
                              <Link className="nav-link" to="/">
                                Home <span className="sr-only">(current)</span>
                              </Link>
                            </li>
                            {navbarData &&
                              navbarData.map((element, index) => {
                                return (
                                  <>
                                    <li
                                      key={index}
                                      className={
                                        element.hasChildren.length > 0
                                          ? "menu-item menu-item-has-children"
                                          : "menu-item"
                                      }
                                    >
                                      <Link to={element.route}>
                                        {element.name}
                                      </Link>
                                      {element.hasChildren && (
                                        <>
                                          <ul
                                            className="sub-menu"
                                            aria-labelledby="navbarDropdown"
                                          >
                                            {element.hasChildren.map(
                                              (children, i) => {
                                                return (
                                                  <>
                                                    <li
                                                      key={i}
                                                      className="menu-item"
                                                    >
                                                      <Link to={children.route}>
                                                        {children.name}
                                                      </Link>
                                                    </li>
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </>
                                      )}
                                    </li>
                                  </>
                                );
                              })}
                          </ul>
                        </div>
                      </nav>
                    </div>
                  </div>
                  <div className="join-sec">
                    <Link to="/order-now">order now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

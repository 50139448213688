import BackgroundImage from "../images/pckg-bg.jpg";
const OurPackagesData = {
  background: BackgroundImage,
  heading: "Our Packages",
  description:
    "We are the website bulder that can help you visualize even the caraziest ideas converting them into elegant designs, awesome experiences and catchy brands.",
  button1: {
    name: "Let's Discuss ",
    link: "/",
    icon: "fas fa-chevron-circle-right",
  },
  button2: {
    name: "+1(855) 275-7741",
    link: "/",
    icon: "fas fa-chevron-circle-right",
  },
  tablist: [
    {
      name: "Website Design",
      isActive: true,
      id: "sitedesign-tab",
      bsTarget: "#sitedesign",
      ariaControls: "sitedesign",
      ariaSelected: true,
      content: [
        {
          title: "Basic Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "199",
          strikedPrice: "$663",
          bestSellerTag: true,
          features: [
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "/",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
          button: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Startup Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "399",
          strikedPrice: "$663",
          bestSellerTag: false,
          features: [
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "/",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Professional Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "499",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Basic Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "199",
          strikedPrice: "$663",
          bestSellerTag: "true",
          features: [
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Startup Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "399",
          strikedPrice: "$663",
          bestSellerTag: false,
          features: [
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Professional Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "499",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
      ],
    },
    {
      name: "E-commerce",
      isActive: false,
      id: "e-commerce-tab",
      bsTarget: "#e-commerce",
      ariaControls: "e-commerce",
      ariaSelected: false,
      content: [
        {
          title: "Basic Web Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "199",
          strikedPrice: "$663",
          bestSellerTag: "true",
          features: [
            "3-5 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Startup Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "399",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Professional Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "499",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Basic Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "199",
          strikedPrice: "$663",
          bestSellerTag: "true",
          features: [
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Startup Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "399",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Professional Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "499",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
      ],
    },
    {
      name: "Web Development",
      isActive: false,
      id: "webdev-tab",
      bsTarget: "#webdev",
      ariaControls: "webdev",
      ariaSelected: false,
      content: [
        {
          title: "Basic Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "199",
          strikedPrice: "$663",
          bestSellerTag: "true",
          features: [
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Startup Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "399",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Professional Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "499",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Basic Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "199",
          strikedPrice: "$663",
          bestSellerTag: "true",
          features: [
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Startup Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "399",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Professional Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "499",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
      ],
    },
    {
      name: "Mobile Apps",
      isActive: false,
      id: "apps-tab",
      bsTarget: "#apps",
      ariaControls: "apps",
      ariaSelected: "false",
      content: [
        {
          title: "Basic Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "199",
          strikedPrice: "$663",
          bestSellerTag: "true",
          features: [
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Startup Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "399",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Professional Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "499",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Basic Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "199",
          strikedPrice: "$663",
          bestSellerTag: "true",
          features: [
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Startup Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "399",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Professional Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "499",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
      ],
    },
    {
      name: "Wordpress",
      isActive: false,
      id: "wordpress-tab",
      bsTarget: "#wordpress",
      ariaControls: "wordpress",
      ariaSelected: "false",
      content: [
        {
          title: "Basic Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "199",
          strikedPrice: "$663",
          bestSellerTag: "true",
          features: [
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Startup Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "399",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Professional Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "499",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Basic Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "199",
          strikedPrice: "$663",
          bestSellerTag: "true",
          features: [
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Startup Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "399",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Professional Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "499",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
      ],
    },
    {
      name: "Custom CMS",
      isActive: false,
      id: "custom-tab",
      bsTarget: "#custom",
      ariaControls: "custom",
      ariaSelected: "false",
      content: [
        {
          title: "Basic Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "199",
          strikedPrice: "$663",
          bestSellerTag: "true",
          features: [
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Startup Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "399",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Professional Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "499",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Basic Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "199",
          strikedPrice: "$663",
          bestSellerTag: "true",
          features: [
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Startup Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "399",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
        {
          title: "Professional Website Package",
          description:
            "Suitable for potential super-startups brand revamps for companies",
          price: "499",
          strikedPrice: "$663",
          bestSellerTag: "false",
          features: [
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "3 page Website Design",
            "Custom layout Design",
            "Contact/Query form",
            "1 Banner Design",
            "2 Stock Photos",
            "Free Favicon Design",
            "Cross Browser Compability",
            "Complete w3c Certified HTML",
          ],
          packageOfferPercent: "20%",
          Phone: {
            name: "+1(858) 707-7622",
            link: "tel:+(858) 707-7622",
          },
          Chat: {
            name: "Live Chat Now",
            link: "/",
          },
        },
      ],
    },
  ],
};

export default OurPackagesData;

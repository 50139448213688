import React, { useState, useEffect } from "react";
// import webPackage from "../../Data/webPackage";
import { Link } from "react-router-dom";
import axios from "axios";

const CustomizedWebPortal = () => {
  const [webPackage, setWebPackage] = useState(null);

  useEffect(() => {
    axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getWebPackages")
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
        } else {
          setWebPackage(res.data.data);
        }
      });
  }, []);
  return (
    <>
      {webPackage && (
        <section class="customize">
          <div class="container">
            <div class="headings-extra">
              <strong>{webPackage.title}</strong>
              <h5>{webPackage.heading}</h5>
              <small>{webPackage.description}</small>
            </div>
            <div class="row">
              <div class="col-lg-9">
                <ul class="cust-list">
                  {webPackage.featureList &&
                    webPackage.featureList.map((element, index) => {
                      return (
                        <>
                          {/* key={index} */}
                          <li>{element.feature}</li>
                        </>
                      );
                    })}
                </ul>
              </div>
              <div class="col-lg-3">
                <div class="pkg-flyer">
                  <div class="heads">
                    <h6>{webPackage.pkg_box_heading}</h6>
                    <small>{webPackage.pkg_box_description}</small>
                  </div>
                  <div class="prices">
                    <strong>
                      <small>$</small>
                      {webPackage.pkg_box_price}
                    </strong>
                    <span>
                      {webPackage.pkg_box_percentageOff}% OFF on your next order
                    </span>
                  </div>
                  <div class="pckg-goto">
                    <div class="call-us">
                      <strong>Speak with us</strong>
                      <Link to={webPackage.pkg_box_button1Link}>
                        {webPackage.pkg_box_button1Phone}
                      </Link>
                    </div>
                    <div class="chat-us">
                      <strong>Want to discuss?</strong>
                      <Link to={webPackage.pkg_box_button2Link}>
                        {webPackage.pkg_box_button2Name}
                      </Link>
                    </div>
                  </div>
                  <Link
                    class="p-start"
                    to={webPackage.pkg_box_startProjectLink}
                  >
                    start project
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CustomizedWebPortal;

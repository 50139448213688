import React from "react";
import Home from "../Pages/Home/Home";

import axios from "axios";

import { Route, Routes } from "react-router-dom";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import PageNotFound from "../Components/Common/PageNotFound";
import ScrollTop from "../Components/ScrollTop/ScrollTop";
import Service from "../Pages/Service/Service";
import { useEffect } from "react";
import { useState } from "react";
import PageFormat from "../Pages/Sub-Pages/PageFormat";
import OrderNow from "../Pages/OrderNow";

function App() {
  const [menuData, setMenuData] = useState(null);

  useEffect(() => {
    const getMenus = async () => {
      const data = await axios.get(
        "https://backend.seo-calgary.ca/api/v1/Pages/getMenus",
        {
          headers: {
            accessToken: localStorage.getItem("authtoken"),
          },
        }
      );
      if (data.data.error) {
        console.log(data.data.error);
      } else {
        setMenuData(data.data);
      }
    };
    getMenus();
  }, []);
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/services" exact element={<Service />} />
        <Route path="/order-now" exact element={<OrderNow />}  />
        <Route path="*" exact element={<PageNotFound />} />
        {menuData &&
          menuData.map((menu) => {
            return (
              <>
                <Route
                  path={menu.route}
                  exact
                  element={<PageFormat data={menu} />}
                />
              </>
            );
          })}
      </Routes>
      <div
        class="modal fade"
        id="reqpModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <OrderNow title={"Request a Quote"}/>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollTop />
    </>
  );
}

export default App;

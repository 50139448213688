import React, { useEffect, useState } from "react";

// import serviceData from "../../Data/OurServicesData";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";

const OurServices = () => {
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getService")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setServiceData(res.data);
          }
        });
    };
    fetchData();
  }, []);

  const image_key =
    serviceData && serviceData.backgroundImage.substring("Images/".length);
  const bgImage = serviceData && `https://backend.seo-calgary.ca/Images/${image_key}`;
  const featuredImage =
    serviceData && `https://backend.seo-calgary.ca/${serviceData.featuredImage}`;

  const encode = encodeURI(bgImage);
  const decode = decodeURI(encode);

  console.log(encode, decode);
  return (
    <>
      {serviceData && (
        <section class="craft" style={{ backgroundImage: `url(${decode})` }}>
          <div class="container">
            {serviceData.heading && (
              <div class="headings">
                <h3>{parse(serviceData.heading)}</h3>
              </div>
            )}
            <div class="row align-items-center">
              <div class="col-lg-6">
                <ul class="craft-list">
                  {serviceData.services &&
                    serviceData.services.map((element, index) => {
                      return (
                        <>
                          <li key={index}>
                            <Link to={element.link}>{element.name}</Link>
                          </li>
                        </>
                      );
                    })}
                </ul>
                <div class="craft-goto">
                  {serviceData.button1Name && (
                    <Link href={serviceData.button1Link}>
                      {serviceData.button1Name}
                    </Link>
                  )}
                  {serviceData.button2Name && (
                    <Link href={serviceData.button2Link}>
                      {serviceData.button2Name}
                    </Link>
                  )}
                </div>
              </div>
              <div class="col-lg-6">
                {serviceData.featuredImage && (
                  <div class="img-wrap">
                    <img src={featuredImage} alt='featured_image' />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default OurServices;

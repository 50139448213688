import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import parse from "html-react-parser";

import axios from "axios";
import OurPackages from "../../Components/Pricing/OurPackages";
import OurWork from "../../Components/Portfolio/OurWork";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { generateCaptcha } from "../../functions/generateCaptcha";

const initialFormValues = {
  name: "",
  email: "",
  phone: "",
  description: "",
  captcha: "",
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const BannerComponent = ({ pageData }) => {
  const image_key =
    pageData &&
    pageData.banner_image &&
    pageData.banner_image.substring("Images/".length);
  const bgImage = pageData && `https://backend.seo-calgary.ca/Images/${image_key}`;

  const encode = encodeURI(bgImage);
  const decode = decodeURI(encode);

  console.log(encode, decode);
  return (
    <>
      <div
        class="banner-1"
        style={
          pageData.banner_image ? { backgroundImage: `url(${decode})` } : null
        }
      >
        <div class="banner-headers">
          <h3>{pageData.title}</h3>
          <span>{pageData.heading}</span>
        </div>
      </div>
    </>
  );
};

const PageFormat = ({ data }) => {
  const { pathname } = useLocation();

  const [captchaText, setCaptchaText] = useState("");

  const [pageData, setPageData] = useState(null);

  const [contactData, setContactData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/settings/getSettings")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setContactData(res.data.data);
            console.log(res.data.message);
          }
        });
    };

    fetchData();

    setCaptchaText(generateCaptcha);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`https://backend.seo-calgary.ca/api/v1/Pages/pageData/${data.id}`)
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
          } else {
            setPageData(res.data);
          }
        });
    };
    fetchData();
  }, [data, pathname]);

  if (data && data.name === "Packages") {
    return (
      <>
        {pageData && (
          <>
            <BannerComponent pageData={pageData} />
            <OurPackages />
          </>
        )}
      </>
    );
  } else if (data && data.name === "Portfolio") {
    return (
      <>
        {pageData && (
          <>
            <BannerComponent pageData={pageData} />
            <OurWork />
          </>
        )}
      </>
    );
  } else if (data && data.name === "Contact") {
    return (
      <>
        {pageData && (
          <>
            <BannerComponent pageData={pageData} />

            <section class="contact-sec">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-4 col-md-6">
                    <div class="slider-form">
                      <Formik
                        initialValues={initialFormValues}
                        validationSchema={Yup.object({
                          name: Yup.string()
                            .max(20, "Must Be 20 Letters Only")
                            .required("Name is Required"),
                          email: Yup.string()
                            .email("Field should contain a valid e-mail")
                            .max(255)
                            .required("E-mail is required"),
                          phone: Yup.string()
                            .matches(phoneRegExp, "Phone number is not valid")
                            .required("phone Number is required"),
                          description: Yup.string()
                            .max(200, "Must Be 200 Letters Only")
                            .required("Description is Required"),
                          captcha: Yup.string()
                            .required("Captcha is Required")
                            .test(
                              "captcha-matches",
                              "captcha must match",
                              (value) => {
                                return captchaText === value;
                              }
                            ),
                        })}
                        onSubmit={(values) => {
                          // if formData Needed
                          // const formData = new FormData();
                          // formData.append('name' , values.name);
                          console.log(values);

                          axios
                            .post(
                              "https://backend.seo-calgary.ca/api/v1/frontendForms/addsliderFormData",
                              values
                            )
                            .then((res) => {
                              if (res.data.error) {
                                window.alert(res.data.error);
                              } else {
                                window.alert(res.data.message);
                              }
                            });
                        }}
                      >
                        <Form className="row">
                          <h2>Connect With Us</h2>
                          <div class="col-md-12">
                            <span className="error-message">
                              <ErrorMessage name="name" />
                            </span>
                            <Field
                              type="text"
                              class="form-control"
                              placeholder="Name *"
                              aria-label="First name"
                              name="name"
                              id="name"
                            />
                          </div>
                          <div class="col-md-12">
                            <span className="error-message">
                              <ErrorMessage name="email" />
                            </span>
                            <Field
                              type="email"
                              class="form-control"
                              placeholder="Email *"
                              aria-label="Email"
                              name="email"
                              id="email"
                            />
                          </div>
                          <div class="col-md-12">
                            <span className="error-message">
                              <ErrorMessage name="phone" />
                            </span>
                            <Field
                              type="text"
                              class="form-control"
                              placeholder="Phone Number"
                              aria-label="Phone Number"
                              name="phone"
                              id="phone"
                            />
                          </div>
                          <div class="col-md-12">
                            <span className="error-message">
                              <ErrorMessage name="description" />
                            </span>
                            <Field
                              as="textarea"
                              class="form-control"
                              placeholder="To help us understand better, write a brief description about your project"
                              aria-label="Description"
                              name="description"
                              rows="4"
                              id="description"
                            />
                          </div>
                          <div class="col-md-12">
                            <p>Captcha Text : {captchaText && captchaText}</p>
                          </div>
                          <div class="col-md-12">
                            <span className="error-message">
                              <ErrorMessage name="captcha" />
                            </span>
                            <Field
                              type="text"
                              class="form-control"
                              placeholder="Captcha Text Here *"
                              aria-label="captcha"
                              name="captcha"
                              id="captcha"
                            />
                          </div>
                          <div class="col-md-12">
                            <Field
                              type="submit"
                              class="btn btn-primary"
                              value="Send Message"
                              name="submit"
                              id="submit"
                            />
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="map-wrap">
                      {contactData && parse(contactData.address_map)}
                    </div>
                  </div>
                  <div class="col-md-12">
                    <ul class="contact-num">
                      <li>
                        <i class="fas fa-phone-alt"></i>
                        <a href={`tel:${contactData.phone_number}`}>{contactData.phone_number}</a>
                      </li>
                      <li>
                        <i class="fas fa-envelope-open"></i>

                        <a href="mailto:manager@globaliTechsystems.com">
                         {contactData.email}
                        </a>
                      </li>
                      <li>
                        <i class="fas fa-map-marker-alt"></i>
                        {contactData.address}<br/>
                        {contactData.city}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        {pageData && (
          <>
            <BannerComponent pageData={pageData} />
            <section class="serv-single">
              <div class="container">
                <div class="row">
                  <div class="pckg-detail">
                    {pageData.description && parse(pageData.description)}
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </>
    );
  }
};

export default PageFormat;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

import OurWorkData from "../../Data/OurWorkData";

const OurWork = () => {
  const [ourWorkData, setOurWorkData] = useState(null);

  useEffect(() => {
    axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getOurWorkDataForView")
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
        } else {
          setOurWorkData(res.data.data);
        }
      });
  }, []);

  console.log(ourWorkData);

  const image_key =
  ourWorkData && ourWorkData.backgroundImage.substring("Images/".length);
const bgImage = ourWorkData && `https://backend.seo-calgary.ca/Images/${image_key}`;

  return (
    <>
      {ourWorkData && (
        <section
          class="our-work"
          style={
            OurWorkData.background && {
              backgroundImage: `url(${bgImage})`,
            }
          }
        >
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-md-6">
                <div class="headings">
                  <h3>{ourWorkData.heading}</h3>
                  <p>{ourWorkData.description}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="work-goto">
                  <Link to={ourWorkData.button1Link}>
                    {ourWorkData.button1Name}
                    <i class={ourWorkData.button1Icon}></i>
                  </Link>
                  <Link to={ourWorkData.button2Link}>
                    {ourWorkData.button2Name}
                    <i class={ourWorkData.button2Icon}></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="tab-section">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                {ourWorkData.tabListData &&
                  ourWorkData.tabListData.map((tab, index) => {
                    return (
                      <>
                        <li key={index} class="nav-item" role="presentation">
                          <button
                            class={
                              tab.isActive === 1
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id={tab.id}
                            data-bs-toggle="tab"
                            data-bs-target={tab.bsTarget}
                            type="button"
                            role="tab"
                            aria-controls={tab.ariaControls}
                            aria-selected={tab.ariaSelected}
                          >
                            {tab.name}
                          </button>
                        </li>
                      </>
                    );
                  })}
              </ul>
              <div class="tab-content" id="nav-tabContent">
                {ourWorkData.tabListData &&
                  ourWorkData.tabListData.map((element, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          class={`tab-pane fade ${
                            element.isActive === 1 ? "show active" : null
                          } `}
                          id={element.ariaControls}
                          role="tabpanel"
                          aria-labelledby={element.tabId}
                        >
                          <div class="row">
                            {element.content &&
                              element.content.map((singleContent, key) => {
                                return (
                                  <>
                                    <div key={key} class="col-12 col-md-3">
                                      {singleContent.alt && (
                                        <div class="work-img">
                                          <Link to={singleContent.link}>
                                            <img
                                              src={`https://backend.seo-calgary.ca/${singleContent.image}`}
                                              alt={singleContent.alt}
                                            />
                                            <div class="img-detail">
                                              <strong>
                                                {singleContent.title}
                                              </strong>
                                            </div>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default OurWork;

import React, { useEffect, useState } from "react";

import axios from "axios";
import ProcessData from "../../Data/ProcessData";

const Process = () => {
  const [processData, setProcessData] = useState(null);
  useEffect(() => {
    axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getProcessforView")
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
        } else {
          setProcessData(res.data.data);
        }
      });
  }, []);

  console.log(processData);
  return (
    <>
      {processData && (
        <section class="process">
          <div class="container">
            <div class="headings-extra">
              <h5>{processData.heading}</h5>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="underline"></div>
              </div>

              {processData.listData &&
                processData.listData.map((element, key) => {
                  return (
                    <>
                      <div class="col">
                        <div class="s-no">{key + 1}</div>
                        <div class="procs-wrap">
                          <div class="icon-wrap">
                            <figure>
                              <img
                                src={`https://backend.seo-calgary.ca/${element.image}`}
                                alt={element.alt}
                              />
                            </figure>
                          </div>
                          <strong>{element.title}</strong>
                          <p>{element.description}</p>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Process;

import work_img1 from "../images/work-img1.jpg";
import work_img2 from "../images/work-img2.jpg";
import work_img3 from "../images/work-img3.jpg";
import work_img4 from "../images/work-img4.jpg";
import work_img5 from "../images/work-img5.jpg";
import work_img6 from "../images/work-img6.jpg";
import work_img7 from "../images/work-img7.jpg";
import work_img8 from "../images/work-img8.jpg";
import Background from "../images/3d-background-png.jpg";

const OurWorkData = {
  background: Background,
  heading: "Our Work",
  description:
    "Get the perfect design - or any design in over 90 categories. whatever your businee need or budget, we'll help get it done.",
  button1: {
    name: "Let's Discuss ",
    link: "/",
    icon: "fas fa-chevron-circle-right",
  },
  button2: {
    name: "+1(855) 275-7741",
    link: "/",
    icon: "fas fa-chevron-circle-right",
  },
  tablist: [
    {
      name: "Web - design",
      isActive: true,
      id: "webdesign-tab",
      bsTarget: "#webdesign",
      ariaControls: "webdesign",
      ariaSelected: "true",
      content: [
        {
          imageName: work_img1,
          alt: "work_img1",
          link: "work_img1",
          title: "work_img1",
        },
        {
          imageName: work_img2,
          alt: "work_img2",
          link: "work_img2",
          title: "work_img2",
        },
        {
          imageName: work_img3,
          alt: "work_img3",
          link: "work_img3",
          title: "work_img3",
        },
        {
          imageName: work_img4,
          alt: "work_img4",
          link: "work_img4",
          title: "work_img4",
        },
        {
          imageName: work_img5,
          alt: "work_img5",
          link: "work_img5",
          title: "work_img5",
        },
        {
          imageName: work_img6,
          alt: "work_img6",
          link: "work_img6",
          title: "work_img6",
        },
        {
          imageName: work_img7,
          alt: "work_img7",
          link: "work_img7",
          title: "work_img7",
        },
        {
          imageName: work_img8,
          alt: "work_img8",
          link: "work_img8",
          title: "work_img8",
        },
      ],
    },
    {
      name: "Ecommerce - Development",
      isActive: false,
      id: "ecommerce-tab",
      bsTarget: "#ecommerce",
      ariaControls: "ecommerce",
      ariaSelected: "false",
      content: [
        {
          imageName: work_img1,
          alt: "work_img1",
          link: "work_img1",
          title: "work_img1",
        },
        {
          imageName: work_img2,
          alt: "work_img2",
          link: "work_img2",
          title: "work_img2",
        },
        {
          imageName: work_img3,
          alt: "work_img3",
          link: "work_img3",
          title: "work_img3",
        },
        {
          imageName: work_img4,
          alt: "work_img4",
          link: "work_img4",
          title: "work_img4",
        },
        {
          imageName: work_img5,
          alt: "work_img5",
          link: "work_img5",
          title: "work_img5",
        },
        {
          imageName: work_img6,
          alt: "work_img6",
          link: "work_img6",
          title: "work_img6",
        },
        {
          imageName: work_img7,
          alt: "work_img7",
          link: "work_img7",
          title: "work_img7",
        },
        {
          imageName: work_img8,
          alt: "work_img8",
          link: "work_img8",
          title: "work_img8",
        },
      ],
    },
    {
      name: "Logo -design",
      isActive: false,
      id: "logodesign-tab",
      bsTarget: "#logodesign",
      ariaControls: "logodesign",
      ariaSelected: "false",
      content: [
        {
          imageName: work_img1,
          alt: "work_img1",
          link: "work_img1",
          title: "work_img1",
        },
        {
          imageName: work_img2,
          alt: "work_img2",
          link: "work_img2",
          title: "work_img2",
        },
        {
          imageName: work_img3,
          alt: "work_img3",
          link: "work_img3",
          title: "work_img3",
        },
        {
          imageName: work_img4,
          alt: "work_img4",
          link: "work_img4",
          title: "work_img4",
        },
        {
          imageName: work_img5,
          alt: "work_img5",
          link: "work_img5",
          title: "work_img5",
        },
        {
          imageName: work_img6,
          alt: "work_img6",
          link: "work_img6",
          title: "work_img6",
        },
        {
          imageName: work_img7,
          alt: "work_img7",
          link: "work_img7",
          title: "work_img7",
        },
        {
          imageName: work_img8,
          alt: "work_img8",
          link: "work_img8",
          title: "work_img8",
        },
      ],
    },
    {
      name: "Branding",
      isActive: false,
      id: "branding-tab",
      bsTarget: "#branding",
      ariaControls: "branding",
      ariaSelected: "false",
      content: [
        {
          imageName: work_img1,
          alt: "work_img1",
          link: "work_img1",
          title: "work_img1",
        },
        {
          imageName: work_img2,
          alt: "work_img2",
          link: "work_img2",
          title: "work_img2",
        },
        {
          imageName: work_img3,
          alt: "work_img3",
          link: "work_img3",
          title: "work_img3",
        },
        {
          imageName: work_img4,
          alt: "work_img4",
          link: "work_img4",
          title: "work_img4",
        },
        {
          imageName: work_img5,
          alt: "work_img5",
          link: "work_img5",
          title: "work_img5",
        },
        {
          imageName: work_img6,
          alt: "work_img6",
          link: "work_img6",
          title: "work_img6",
        },
        {
          imageName: work_img7,
          alt: "work_img7",
          link: "work_img7",
          title: "work_img7",
        },
        {
          imageName: work_img8,
          alt: "work_img8",
          link: "work_img8",
          title: "work_img8",
        },
      ],
    },
    {
      name: "web - Apps",
      isActive: false,
      id: "webapps-tab",
      bsTarget: "#webapps",
      ariaControls: "webapps",
      ariaSelected: "false",
      content: [
        {
          imageName: work_img1,
          alt: "work_img1",
          link: "work_img1",
          title: "work_img1",
        },
        {
          imageName: work_img2,
          alt: "work_img2",
          link: "work_img2",
          title: "work_img2",
        },
        {
          imageName: work_img3,
          alt: "work_img3",
          link: "work_img3",
          title: "work_img3",
        },
        {
          imageName: work_img4,
          alt: "work_img4",
          link: "work_img4",
          title: "work_img4",
        },
        {
          imageName: work_img5,
          alt: "work_img5",
          link: "work_img5",
          title: "work_img5",
        },
        {
          imageName: work_img6,
          alt: "work_img6",
          link: "work_img6",
          title: "work_img6",
        },
        {
          imageName: work_img7,
          alt: "work_img7",
          link: "work_img7",
          title: "work_img7",
        },
        {
          imageName: work_img8,
          alt: "work_img8",
          link: "work_img8",
          title: "work_img8",
        },
      ],
    },
    {
      name: "Mobile - Apps",
      isActive: false,
      id: "mobile-apps-tab",
      bsTarget: "#mobile-apps",
      ariaControls: "mobile-apps",
      ariaSelected: "false",
      content: [
        {
          imageName: work_img1,
          alt: "work_img1",
          link: "work_img1",
          title: "work_img1",
        },
        {
          imageName: work_img2,
          alt: "work_img2",
          link: "work_img2",
          title: "work_img2",
        },
        {
          imageName: work_img3,
          alt: "work_img3",
          link: "work_img3",
          title: "work_img3",
        },
        {
          imageName: work_img4,
          alt: "work_img4",
          link: "work_img4",
          title: "work_img4",
        },
        {
          imageName: work_img5,
          alt: "work_img5",
          link: "work_img5",
          title: "work_img5",
        },
        {
          imageName: work_img6,
          alt: "work_img6",
          link: "work_img6",
          title: "work_img6",
        },
        {
          imageName: work_img7,
          alt: "work_img7",
          link: "work_img7",
          title: "work_img7",
        },
        {
          imageName: work_img8,
          alt: "work_img8",
          link: "work_img8",
          title: "work_img8",
        },
      ],
    },
    {
      name: "Video - Animation",
      isActive: false,
      id: "vedioanim-tab",
      bsTarget: "#vedioanim",
      ariaControls: "vedioanim",
      ariaSelected: "false",
      content: [
        {
          imageName: work_img1,
          alt: "work_img1",
          link: "work_img1",
          title: "work_img1",
        },
        {
          imageName: work_img2,
          alt: "work_img2",
          link: "work_img2",
          title: "work_img2",
        },
        {
          imageName: work_img3,
          alt: "work_img3",
          link: "work_img3",
          title: "work_img3",
        },
        {
          imageName: work_img4,
          alt: "work_img4",
          link: "work_img4",
          title: "work_img4",
        },
        {
          imageName: work_img5,
          alt: "work_img5",
          link: "work_img5",
          title: "work_img5",
        },
        {
          imageName: work_img6,
          alt: "work_img6",
          link: "work_img6",
          title: "work_img6",
        },
        {
          imageName: work_img7,
          alt: "work_img7",
          link: "work_img7",
          title: "work_img7",
        },
        {
          imageName: work_img8,
          alt: "work_img8",
          link: "work_img8",
          title: "work_img8",
        },
      ],
    },
  ],
};

export default OurWorkData;

import React, { useState, useEffect } from "react";
// import businessData from "../../Data/businessData";
import { Link } from "react-router-dom";

import axios from "axios";

const GrowYourBusiness = () => {
  const [businessData, setbusinessData] = useState(null);
  console.log(businessData);

  useEffect(() => {
    axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getGrowYourBusiness")
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
        } else {
          setbusinessData(res.data.data);
        }
      });
  }, []);
  return (
    <>
      {businessData && (
        <section class="customize">
          <div class="container">
            <div class="headings-extra">
              <strong>{businessData.title}</strong>
              <h5>{businessData.heading}</h5>
              <small>{businessData.description}</small>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="grow-sec">
                  <strong>{businessData.features_heading}</strong>
                  <ul class="grow-list">
                    {businessData.featureList &&
                      businessData.featureList.map((element, key) => {
                        return (
                          <>
                            <li>
                              <Link to={element.link}>{element.name}</Link>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </div>
                <div class="craft-goto">
                  <Link to={businessData.button1Link}>
                    <i class={businessData.button1Icon}></i>{" "}
                    {businessData.button1Name}
                  </Link>
                  <Link to={businessData.button2Link}>
                    <i class={businessData.button2Icon}></i>{" "}
                    {businessData.button2Name}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default GrowYourBusiness;

import React, { useEffect, useState } from "react";

import axios from "axios";
import { Link } from "react-router-dom";

const Cta = () => {
  const [ctaData, setctaData] = useState(null);
  useEffect(() => {
    axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getCta")
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
        } else {
          setctaData(res.data.data);
        }
      });
  }, []);
  return (
    <>
    {ctaData && (
  <section class="contact-flyer">
  <div class="container">
    <div class="row">
      <div class="col-lg-7">
        <h6>{ctaData.heading}</h6>
      </div>
      <div class="col-lg-5">
        <div class="cntc-goto">
          <div class="call-wrap">
            <div class="icn-wrap">
              <i class={ctaData.button1Icon}></i>
            </div>
            <div class="dtls">
              <small>call us</small>
              <Link to={ctaData.button1Link}>
                {ctaData.button1Name}
              </Link>
            </div>
          </div>
          <div class="call-wrap">
            <div class="icn-wrap">
              <i class={ctaData.button2Icon}></i>
            </div>
            <div class="dtls">
              <small>call us</small>
              <Link to={ctaData.button2Link}>
                {ctaData.button2Name}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    )}
    
    </>
  );
};

export default Cta;

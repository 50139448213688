import React, { useState, useEffect } from "react";
import WhyUsData from "../../Data/WhyUsData";

import axios from "axios";

const WhyUs = () => {
  const [whyUsData, setWhyUsData] = useState(null);

  useEffect(() => {
    axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getWhyUsDataForView")
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
        } else {
          setWhyUsData(res.data.data);
        }
      });
  }, []);

  return (
    <>
      <section class="why-us">
        <div class="container">
          <div class="row">
            {whyUsData &&
              whyUsData.map((element, key) => {
                return (
                  <>
                    <div class="col">
                      <div class="why-wrap">
                        <div class="icon-warp">
                          <i class={element.icon}></i>
                        </div>
                        <strong>{element.text}</strong>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyUs;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import OurPackagesData from "../../Data/OurPackagesData";

import axios from "axios";

const OurPackages = () => {
  const [ourPackageData, setOurPackageData] = useState(null);

  useEffect(() => {
    axios
      .get("https://backend.seo-calgary.ca/api/v1/dashboards/getOurPackageDataForView")
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
        } else {
          setOurPackageData(res.data.data);
        }
      });
  }, []);

  const image_key =
  ourPackageData && ourPackageData.backgroundImage.substring("Images/".length);
const bgImage = ourPackageData && `https://backend.seo-calgary.ca/Images/${image_key}`;

  console.log(ourPackageData);
  return (
    <>
      {ourPackageData && (
        <section
          class="our-work packages"
          style={
            OurPackagesData.background && {
              backgroundImage: `url(${bgImage})`,
            }
          }
        >
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-md-6">
                <div class="headings">
                  <h3>{ourPackageData.heading}</h3>
                  <p>{ourPackageData.description}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="work-goto">
                  <Link to={ourPackageData.button1Link}>
                    {ourPackageData.button1Name}
                    <i class={ourPackageData.button1Icon}></i>
                  </Link>
                  <Link to={ourPackageData.button2Link}>
                    {ourPackageData.button2Name}
                    <i class={ourPackageData.button2Icon}></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="tab-section">
              <ul class="nav nav-tabs" id="pTab" role="tablist">
                {ourPackageData.tabListData &&
                  ourPackageData.tabListData.map((tab, index) => {
                    return (
                      <>
                        <li key={index} class="nav-item" role="presentation">
                          <button
                            class={
                              tab.isActive === 1 ? "nav-link active" : "nav-link"
                            }
                            id={tab.tabId}
                            data-bs-toggle="tab"
                            data-bs-target={tab.bsTarget}
                            type="button"
                            role="tab"
                            aria-controls={tab.ariaControls}
                            aria-selected={tab.ariaSelected}
                          >
                            {tab.name}
                          </button>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </div>

            <div class="tab-content" id="nav-tabContent">
              {ourPackageData.tabListData &&
                ourPackageData.tabListData.map((element, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        class={`tab-pane fade ${
                          element.isActive === 1 ? "show active" : null
                        } `}
                        id={element.ariaControls}
                        role="tabpanel"
                        aria-labelledby={element.tabId}
                      >
                        <div class="row">
                          {element.content &&
                            element.content.map((singledeal, key) => {
                              return (
                                <>
                                  <div
                                    key={key}
                                    class="col-12 col-md-6 col-lg-4"
                                  >
                                    <div class="pckg-wrap">
                                      <div class="pckg-heads">
                                        <h4>{singledeal.title}</h4>
                                        <small>{singledeal.description}</small>
                                      </div>
                                      <div class="pckg-price">
                                        <div class="price">
                                          <small>$</small>
                                          {singledeal.price}{" "}
                                          <strong>
                                            <strike>
                                              {singledeal.strikedPrice}
                                            </strike>{" "}
                                            <br />
                                            Only
                                          </strong>
                                        </div>
                                      </div>
                                      <ul class="p-feature">
                                        {singledeal.features &&
                                          singledeal.features.map(
                                            (singleFeature, count) => {
                                              return (
                                                <>
                                                  <li>{singleFeature.feature}</li>
                                                </>
                                              );
                                            }
                                          )}
                                      </ul>
                                      <div class="pckg-off">
                                        <p>
                                          <span>
                                            {singledeal.packageOfferPercent}
                                          </span>{" "}
                                          OFF on your Next Order
                                        </p>
                                      </div>
                                      <div class="pckg-goto">
                                        <div class="call-us">
                                          <strong>Speak with us</strong>
                                          <Link to={singledeal.phoneLink}>
                                            {singledeal.phoneNumber}
                                          </Link>
                                        </div>
                                        <div class="chat-us">
                                          <strong>Want to discuss?</strong>
                                          <Link to={singledeal.chatLink}>
                                            {singledeal.chatName}
                                          </Link>
                                        </div>
                                      </div>
                                      {singledeal.bestSellerTag ? (
                                        <span class="best-tag">
                                          best seller
                                        </span>
                                      ) : null}
                                    </div>
                                    <div class="pckg-start">
                                      <Link
                                        to={singledeal.buttonLink}
                                        data-bs-toggle="modal"
                                        data-bs-target="#startpModal"
                                      >
                                        {singledeal.buttonName}
                                      </Link>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default OurPackages;

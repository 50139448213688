import React, { useEffect, useState } from "react";

// Owl Carousel For React
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import axios from "axios";

const TechnoSlider = () => {
  const [technoSliderData, setTechnoSliderData] = useState([]);
  console.log(setTechnoSliderData);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getTechnoSlidersForView")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setTechnoSliderData(res.data);
          }
        });
    };
    fetchData();
  }, []);

  const TechnoProps = {
    className: "owl-carousel owl-theme",
    id: "techno-slider",
    loop: true,
    navText: [
      "<i class='fas  fa-long-arrow-alt-left '></i>",
      "<i class='fas fa-long-arrow-alt-right '></i>",
    ],
    nav: false,
    autoplay: true,
    dots: false,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 6,
      },
    },
  };
  return (
    <>
     {technoSliderData.length > 0 && (
      <section class="techno">
        <div class="container">
          <div class="row">
            <OwlCarousel {...TechnoProps}>
              {technoSliderData &&
                technoSliderData.map((element, index) => {
                  return (
                    <>
                      {element.sliderImage && (
                        <div key={index} class="img-wrap">
                          <img
                            src={`https://backend.seo-calgary.ca/${element.sliderImage}`}
                            alt={`Slider_Image_${index}`}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
            </OwlCarousel>
          </div>
        </div>
      </section>
     )}
    </>
  );
};

export default TechnoSlider;

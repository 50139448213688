import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div>PageNotFound
        <Link to="/"><p>Click Me to Get Back to Home Now</p></Link>
        <a href='/'>a tag</a>
    </div>
  )
}

export default PageNotFound
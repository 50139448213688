import React, { useEffect, useState } from "react";


import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import axios from "axios";
import { generateCaptcha } from "../functions/generateCaptcha";

const OrderNow = ({title}) => {
    const [captchaText, setCaptchaText] = useState("");

    useEffect(() => {
      setCaptchaText(generateCaptcha);
    }, []);
  
    const initialFormValues = {
      name: "",
      email: "",
      phone: "",
      company_name: "",
      message: "",
      type: "",
      captcha: "",
    };
  
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  return (
   <>
   <section class="signup-sec">
        <div class="container">
          <div class="row">
              <div class="signup-form">
                <Formik
                  initialValues={initialFormValues}
                  validationSchema={Yup.object({
                    name: Yup.string()
                      .max(20, "Must Be 20 Letters Only")
                      .required("Name is Required"),
                    email: Yup.string()
                      .email("Field should contain a valid e-mail")
                      .max(255)
                      .required("E-mail is required"),
                    phone: Yup.string()
                      .matches(phoneRegExp, "Phone number is not valid")
                      .required("phone Number is required"),
                    company_name: Yup.string()
                      .max(20, "Must Be 20 Letters Only")
                      .required("company name is Required"),
                    type: Yup.string().required("type is Required"),
                    message: Yup.string()
                      .max(200, "Must Be 200 Letters Only")
                      .required("Description is Required"),
                    captcha: Yup.string()
                      .required("Captcha is Required")
                      .test(
                        "captcha-matches",
                        "captcha must match",
                        (value) => {
                          return captchaText === value;
                        }
                      ),
                  })}
                  onSubmit={(values) => {
                    // if formData Needed
                    // const formData = new FormData();
                    // formData.append('name' , values.name);
                    console.log(values);

                    axios
                      .post(
                        "https://backend.seo-calgary.ca/api/v1/frontendForms/addLeadData",
                        values
                      )
                      .then((res) => {
                        if (res.data.error) {
                          window.alert(res.data.error);
                        } else {
                          window.alert(res.data.message);
                        }
                      });
                  }}
                >
                  <Form class="row">
                    <h2>{title ? title :" Sign up to get your custom made design" }</h2>
                    <div class="col-md-4">
                      <span className="error-message">
                        <ErrorMessage name="name" />
                      </span>
                      <Field
                        type="text"
                        class="form-control"
                        placeholder="Name *"
                        name="name"
                        id="name"
                        aria-label="First name"
                      />
                    </div>
                    <div class="col-md-4">
                      <span className="error-message">
                        <ErrorMessage name="email" />
                      </span>
                      <Field
                        type="email"
                        class="form-control"
                        placeholder="Email *"
                        aria-label="Email"
                        name="email"
                        id="email"
                      />
                    </div>
                    <div class="col-md-4">
                      <span className="error-message">
                        <ErrorMessage name="phone" />
                      </span>
                      <Field
                        type="text"
                        class="form-control"
                        placeholder="Phone Number"
                        aria-label="Phone Number"
                        name="phone"
                        id="phone"
                      />
                    </div>
                    <div class="col-md-6">
                      <span className="error-message">
                        <ErrorMessage name="company_name" />
                      </span>
                      <Field
                        type="text"
                        class="form-control"
                        placeholder="company Name *"
                        aria-label="company_name"
                        name="company_name"
                        id="company_name"
                      />
                    </div>
                    <div class="col-md-6">
                      <span className="error-message">
                        <ErrorMessage name="message" />
                      </span>
                      <Field
                        as="textarea"
                        class="form-control"
                        placeholder="message"
                        aria-label="with textarea"
                        name="message"
                        rows="4"
                        id="message"
                      />
                    </div>
                    <div class="col-md-9">
                      <div class="form-check form-check-inline">
                        <Field
                          class="form-check-input"
                          type="radio"
                          name="type"
                          id="type1"
                          value="Web Design"
                        />
                        <label class="form-check-label" htmlFor="type1">
                          Web Design
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <Field
                          class="form-check-input"
                          type="radio"
                          name="type"
                          id="type2"
                          value="E Commerce"
                        />
                        <label class="form-check-label" htmlFor="type2">
                          E Commerce
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <Field
                          class="form-check-input"
                          type="radio"
                          name="type"
                          id="type3"
                          value="Portal Development"
                        />
                        <label class="form-check-label" htmlFor="type3">
                          POrtal Development
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <Field
                          class="form-check-input"
                          type="radio"
                          name="type"
                          id="type4"
                          value=" Wordpress"
                        />
                        <label class="form-check-label" htmlFor="type4">
                          Wordpress
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <Field
                          class="form-check-input"
                          type="radio"
                          name="type"
                          id="type5"
                          value="Mobile Application"
                        />
                        <label class="form-check-label" htmlFor="type5">
                          Mobile Application
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <Field
                          class="form-check-input"
                          type="radio"
                          name="type"
                          id="type6"
                          value="SEO"
                        />
                        <label class="form-check-label" htmlFor="type6">
                          SEO
                        </label>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <p>Captcha Text : {captchaText && captchaText}</p>
                    </div>
                    <div class="col-md-12">
                      <span className="error-message">
                        <ErrorMessage name="captcha" />
                      </span>
                      <Field
                        type="text"
                        class="form-control"
                        placeholder="Captcha Text Here *"
                        aria-label="captcha"
                        name="captcha"
                        id="captcha"
                      />
                    </div>
                    <div class="col-md-3">
                      <Field
                        type="submit"
                        value="send"
                        name="submit"
                        class="btn btn-primary"
                      />
                    </div>
                  </Form>
                </Formik>
              </div>         
          </div>
        </div>
      </section>
   </>
  )
}

export default OrderNow
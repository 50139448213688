import React from "react";
import Cta from "../../Components/CTA/Cta";
import CustomizedWebPortal from "../../Components/CustomizedWebPortal/CustomizedWebPortal";
import GrowYourBusiness from "../../Components/GrowYourBusiness/GrowYourBusiness";
import OurWork from "../../Components/Portfolio/OurWork";
import OurPackages from "../../Components/Pricing/OurPackages";
import Process from "../../Components/Process/Process";
import OurServices from "../../Components/Services/OurServices";
import SignUp from "../../Components/SignUp/SignUp";
import IndusrtySlider from "../../Components/Sliders/IndusrtySlider";
import MainSlider from "../../Components/Sliders/MainSlider";
import TechnoSlider from "../../Components/Sliders/TechnoSlider";
import Testimonials from "../../Components/Testimonials/Testimonials";
import WhyUs from "../../Components/WhyUs/WhyUs";
import OrderNow from "../OrderNow";

const Home = () => {
  return (
    <>
      <MainSlider />
      <TechnoSlider />
      <OurServices />
      <IndusrtySlider />
      <OurWork />
      <OurPackages />
      <CustomizedWebPortal />
      <WhyUs />
      <GrowYourBusiness />
      <Process />
      <Cta />
      <Testimonials />
      <SignUp />
    </>
  );
};

export default Home;

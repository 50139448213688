import React from 'react';
import ReactDOM from 'react-dom/client';

// bootstarp
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

// custom Css
import './Css/index.css';
import './Css/responsive.css';
import './Css/all.css';

// Import App Component
import App from './App/App';

import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import axios from "axios";
// Owl Carousel For React
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import industrySliders from "../../Data/IndustryslideData";
import parse from "html-react-parser";

const IndusrtySlider = () => {
  const IndustryProps = {
    className: "owl-carousel owl-theme",
    id: "indus-slider",
    loop: true,
    navText: [
      "<i class='fas  fa-long-arrow-alt-left '></i>",
      "<i class='fas fa-long-arrow-alt-right '></i>",
    ],
    nav: false,
    autoplay: true,
    dots: false,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 6,
      },
    },
  };

  const [industrySliders, setIndustrySliders] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get("https://backend.seo-calgary.ca/api/v1/dashboards/getIndustrySliderforView")
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            console.log(res.data);
            setIndustrySliders(res.data.data);
          }
        });
    };
    fetchData();
  }, []);

  const image_key =
    industrySliders &&
    industrySliders.backgroundImage.substring("Images/".length);
  const bgImage =
    industrySliders && `https://backend.seo-calgary.ca/Images/${image_key}`;

  const encode = encodeURI(bgImage);
  const decode = decodeURI(encode);

  console.log(encode, decode);
  return (
    <>
      {industrySliders && (
        <section
          class="indus"
          // style = {{backgroundImage: `url(${BackgroundImage})`}}
          style={
            industrySliders.backgroundImage
              ? { backgroundImage: `url(${decode})` }
              : null
          }
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-2">
                <div class="indus-header">
                  {industrySliders.heading && (
                    <h3>{parse(industrySliders.heading)}</h3>
                  )}
                </div>
              </div>
              <div class="col-md-10">
                <div class="indus-slide">
                  <OwlCarousel {...IndustryProps}>
                    {industrySliders.slideData &&
                      industrySliders.slideData.map((element, index) => {
                        return (
                          <>
                            <Link to={element.link}>
                              <div key={index} class="text-wrap">
                                <strong>{element.title}</strong>
                              </div>
                            </Link>
                          </>
                        );
                      })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default IndusrtySlider;
